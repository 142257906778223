import React from "react";
import Logo from "./Logo";
import imgTwitterLogo from "../img/twitter-logo.svg"
import imgInstagramLogo from "../img/instagram-logo.svg"
import imgBehanceLogo from "../img/behance-logo.svg"
import imgLinkedinLogo from "../img/linkedin-logo.svg"

function Footer() {
    return (
        <div className="footer-section">
            <div className="main-container">
                <div className="footer-wrapper">
                    <div className="footer-top" style={{ width: "100%"}}>
                        <div className="footer-left">
                            <div className="footer-left-content" style={{ display: "flex", flexDirection: "column", rowGap: 12}}>
                                <Logo />
                                <p className="body-text-n-reg">
                                    Whether you want to make a new website, mobile app or talk about our white-label solutions,
                                    we're ready to meet you!
                                </p>
                            </div>
                            <div className="footer-left-content">
                                <h4>Offices:</h4>
                                <div className="body-text-n-reg margin-top-4px">
                                    179 Victoria Street, Pietermaritzburg Central 3201, KwaZulu Natal
                                </div>
                            </div>
                        </div>
                        <div className="footer-right">
                            <div id="w-node-_8beda1ba-4ecc-b012-87f1-58e1c04d9c71-c04d9c60" className="footer-menu-wrapper">
                                <div id="w-node-_8beda1ba-4ecc-b012-87f1-58e1c04d9c72-c04d9c60" className="footer-menu-component">
                                    <div className="text-style-h5">Menu</div>
                                    <ul role="list" className="footer-link">
                                        <li>
                                            <a href="/" aria-current="page" className="w-inline-block">
                                                <div className="footer-menu-link">Home</div>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="/our-work" className="w-inline-block">
                                                <div className="footer-menu-link">Our Work</div>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                                <div id="w-node-_8beda1ba-4ecc-b012-87f1-58e1c04d9c92-c04d9c60" className="footer-menu-component">
                                    <div className="text-style-h5">Menu</div>
                                    <ul role="list" className="footer-link">
                                        <li>
                                            <a href="/about-us" className="w-inline-block">
                                                <div className="footer-menu-link">About</div>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="/contact-us" className="w-inline-block">
                                                <div className="footer-menu-link">Contact Us</div>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="line-vertical"></div>
                            <div id="w-node-_8beda1ba-4ecc-b012-87f1-58e1c04d9cab-c04d9c60"
                                 className="footer-subscription-container">
                                <h2>Subscription</h2>
                                <div className="body-text-l-med margin-top-12px">
                                    Join our newsletter to stay up to date on our white-label solutions topic.
                                </div>
                                <div className="w-form">
                                    <form id="email-form" name="email-form" method="get" className="subscription-form">
                                        <input type="email" className="text-field-form w-input" maxLength="256"
                                               placeholder="" id="email-2" required=""/>
                                        <input type="submit" value="Submit" className="button-primary w-button"/>
                                    </form>
                                    <div className="w-form-done">
                                        <div>Thank you! Your submission has been received!</div>
                                    </div>
                                    <div className="w-form-fail">
                                        <div>Oops! Something went wrong while submitting the form.</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="footer-bottom">
                    <div className="social-block">
                        <a id="w-node-_8beda1ba-4ecc-b012-87f1-58e1c04d9cbd-c04d9c60" href="https://twitter.com/" target="_blank"
                           className="button-icon w-inline-block">
                            <img src={imgTwitterLogo} loading="lazy" alt=""/>
                        </a>
                        <a id="w-node-_8beda1ba-4ecc-b012-87f1-58e1c04d9cbf-c04d9c60" href="https://linkedin.com/" target="_blank"
                           className="button-icon w-inline-block">
                            <img src={imgLinkedinLogo} loading="lazy" alt=""/>
                        </a>
                        <a id="w-node-_8beda1ba-4ecc-b012-87f1-58e1c04d9cc1-c04d9c60" href="https://www.instagram.com/" target="_blank"
                           className="button-icon w-inline-block">
                            <img src={imgInstagramLogo} loading="lazy" alt=""/>
                        </a>
                        <a id="w-node-_8beda1ba-4ecc-b012-87f1-58e1c04d9cc3-c04d9c60" href="https://www.behance.net/" target="_blank"
                           className="button-icon w-inline-block">
                            <img src={imgBehanceLogo} loading="lazy" alt=""/>
                        </a>
                    </div>
                    <div className="body-text-s-med">© OnlyDev (Pty) Ltd. All rights reserved | {new Date().getFullYear()}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Footer