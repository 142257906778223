import React from "react";
import imgThumbnail from "../img/thumbnail.jpeg"

function OurWork({ item }) {

    return (
        <div role="listitem" className="w-dyn-item">
            <div style={{backgroundColor: item.bgColor}} className="project-component-wrapper">
                <img src={imgThumbnail} loading="lazy" alt="" className="project-image-thumbnail" style={{ border: "3px solid #000", borderRadius: "8px" }}
                     sizes="(max-width: 479px) 78vw, (max-width: 767px) 77vw, (max-width: 991px) 79vw, 36vw" />
                <div className="project-tag-wrapper">
                    {item.platform.map((platformItem) => (
                        <div className="category-tag w-inline-block">
                            <div className="body-text-s-med">{platformItem.name}</div>
                        </div>
                    ))}
                </div>
                <div>
                    <div className="client-name-block">
                        <div className="body-text-l-med">{item.paymentMode} Model</div>
                    </div>
                    <div className="project-name">
                        <div className="text-style-h3">{item.title}</div>
                    </div>
                    <p className="body-text-n-med">{item.shortDescription}</p>
                </div>
                <div className="button-wrapper">
                    <a href={`/our-work/${item.url}`} className="button-secondary w-button">See Detail</a>
                </div>
            </div>
        </div>
    )
}

export default OurWork