import React, {useState} from "react";
import {navLink} from "../util/constants";
import {NavLink} from "react-router-dom";
import Logo from "./Logo";

function Header() {
    const [openNav, setOpenNav] = useState(false)

    const handleOpenNav = () => {
        if (openNav) {
            setOpenNav(false)
        } else {
            setOpenNav(true)
        }
    }

    return (
        <div className="navbar">
            <div data-animation="default" data-collapse="medium" data-duration="400" data-easing="ease"
                 data-easing2="ease" role="banner" className="navbar-component w-nav">
                <div className="navbar-container">
                    <Logo />
                    <nav role="navigation" className="nav-menu w-nav-menu">
                        {navLink.map((item) => (
                            <NavLink to={item.url} className={({isActive}) => isActive ?
                                "nav-link w-nav-link w--current" : "nav-link w-nav-link"}>
                                {item.title}
                            </NavLink>
                        ))}
                    </nav>
                    <div className="right-navbar">
                        <a href="/contact-us" className="button-with-icon w-inline-block">
                            <div className="button-text">Client Login</div>
                        </a>
                    </div>
                    <div className="menu-button w-nav-button" onClick={handleOpenNav}>
                        <div className="w-icon-nav-menu"></div>
                    </div>
                </div>
                <div data-wf-ignore="" id="w-nav-overlay-0" style={{display: openNav ? "block" : "none"}}>
                    <nav role="navigation" className="nav-menu w-nav-menu" data-nav-menu-open=""
                         style={{transform: `translateY(0px)`, transition: `transform 400ms ease 0s`}}>
                        {navLink.map((item) => (
                            <NavLink to={item.url} onClick={handleOpenNav} className={({isActive}) => isActive ?
                                "nav-link w-nav-link w--current w--nav-link-open" : "nav-link w-nav-link w--nav-link-open"}>
                                {item.title}
                            </NavLink>
                        ))}
                    </nav>
                </div>
            </div>
        </div>
    )
}

export default Header