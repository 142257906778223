import React, {useState} from "react";
import imgPlus from "../img/plus.svg";

function OurService({ item }) {
    const [selectedTab, setSelectedTab] = useState(false)

    const handleSelectedTab = () => {
        if (selectedTab) {
            setSelectedTab(false)
        } else {
            setSelectedTab(true)
        }
    }

    return (
        <div>
            <div className="service-dropdown w-dropdown">
                <div className="dropdown-toggle w-dropdown-toggle">
                    <div className="accordion-toggle" onClick={handleSelectedTab}>
                        <div className="text-style-h4">{item.title}</div>
                        <img src={imgPlus} loading="lazy" alt="" />
                    </div>
                </div>
                <nav className={selectedTab ? "service-dropdown-list w-dropdown-list w--open" : "service-dropdown-list w-dropdown-list"}
                     id="w-dropdown-list-0">
                    <p className="body-text-n-reg">{item.description}</p>
                </nav>
            </div>
            <div className="line-divider"></div>
        </div>
    )
}

export default OurService