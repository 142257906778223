import React, {useState} from "react";
import {SEO} from "../seo/SEO";
import {auth, db} from "../firebase";

function ContactUsPage() {
    const [status, setStatus] = useState("")
    const [contact, setContact] = useState({
        firstName: "",
        surname: "",
        phoneNumber: "",
        email: "",
        category: "",
        message: ""
    })

    const handleChange = name => event => {
        const value = event.target.value
        setContact({...contact, [name]: value})
    }

    const submitContact = (e) => {
        e.preventDefault()

        const postId = db.ref().push().key

        auth.signInAnonymously().then((authUser) => {
            db.ref("contactUs").child(`/${authUser.user.uid}/${postId}`).set({
                firstName: contact.firstName,
                surname: contact.surname,
                phoneNumber: contact.phoneNumber,
                email: contact.email,
                postId: postId,
                message: contact.message,
                dateSent: new Date().getTime(),
                userId: authUser.user.uid,
                category: contact.category

            }).then(() => {
                setContact({ firstName: "", surname: "", email: "", message: "", phoneNumber: "", category: "" })
                setStatus("successful")
            }).catch(() => {
                setStatus("error")
            })
        }).catch((err) => {
            setStatus('error')
        })

    }

    return (
        <div className="page-wrapper">
            <SEO
                title="Connect our Software Delveloment Agency | OnlyDev"
                description="OnlyDev is dedicated to making affordable, quality software more accessible while keeing the cost low through our monthly installment payment plan."
                canonicalURL='https://onlydev.co.za/contact-us'
            />
            <div className="main-page">
                <div className="contact-us-header-section">
                    <div className="main-container">
                        <div className="contact-us-header-container">
                            <div id="w-node-_8aaa3742-6f27-e5f5-819c-3a56b31b564d-57f55981" className="contact-us-heading-block">
                                <h1 id="w-node-c553cda1-847f-3f7e-2fb1-ea8ab93a8c66-57f55981">
                                    Get in touch with our team today!
                                </h1>
                            </div>
                            <div id="w-node-b0d818a8-bdd5-45d1-762b-5efc8972c3c9-57f55981">
                                <p className="body-text-l-med">
                                    Connect with us to discuss the ext big project in your mind. Our agents are available
                                    throughout to converse with you. Stay in touch!
                                </p>
                            </div>
                        </div>
                        <div className="contact-us-form-container">
                            <div className="form-cta-component w-form">
                                <form id="wf-form-Form" name="wf-form-Form" className="form-wrapper" onSubmit={submitContact}>
                                    <div className="form-field-wrapper">
                                        <label htmlFor="name" className="form-label-text">First Name</label>
                                        <input type="text" className="text-field-form w-input" required value={contact.firstName}
                                               name="name" placeholder="Enter your name" id="name" onChange={handleChange('firstName')}/>
                                    </div>
                                    <div className="form-field-wrapper">
                                        <label htmlFor="name" className="form-label-text">Surname</label>
                                        <input type="text" className="text-field-form w-input" required value={contact.surname}
                                               name="name" placeholder="Enter your surname" id="name" onChange={handleChange('surname')}/>
                                    </div>
                                    <div className="form-field-wrapper">
                                        <label htmlFor="Email-3" className="form-label-text">Email Address</label>
                                        <input type="email" className="text-field-form w-input" value={contact.email}
                                               name="Email" placeholder="Enter your email" id="Email-3" required onChange={handleChange('email')}/>
                                    </div>
                                    <div className="form-field-wrapper">
                                        <label htmlFor="name" className="form-label-text">Phone Number</label>
                                        <input type="tel" className="text-field-form w-input" required value={contact.phoneNumber}
                                               name="name" placeholder="+27 11 123 4567" id="name" onChange={handleChange("phoneNumber")}/>
                                    </div>
                                    <div className="radio-button-field-wrapper">
                                        <label className="radio-button-field w-radio">
                                            <input type="radio" id="radio" name="radio" value="Web development" onChange={handleChange('category')}
                                                   required className="w-form-formradioinput radio-button w-radio-input"/>
                                            <span className="w-form-label" htmlFor="radio">Web Development</span>
                                        </label>
                                        <label className="w-radio">
                                            <input type="radio" id="radio-2" name="radio" required onChange={handleChange('category')}
                                                   value="Mobile app development" className="w-form-formradioinput radio-button w-radio-input"/>
                                            <span className="w-form-label" htmlFor="radio-2">Mobile App Development</span>
                                        </label>
                                        <label className="w-radio">
                                            <input type="radio" id="radio-3" name="radio" required onChange={handleChange('category')}
                                                   value="White label solution" className="w-form-formradioinput radio-button w-radio-input"/>
                                            <span className="w-form-label" htmlFor="radio-3">White Label Solution</span>
                                        </label>
                                        <label className="w-radio">
                                            <input type="radio" id="radio-4" name="radio" required onChange={handleChange('category')}
                                                   value="Other" className="w-form-formradioinput radio-button w-radio-input"/>
                                            <span className="w-form-label" htmlFor="radio-4">Other</span>
                                        </label>
                                    </div>
                                    <div className="form-field-wrapper">
                                        <label htmlFor="field-3" className="form-label-text">Your Message (optional)</label>
                                        <textarea placeholder="Enter your message here..." maxLength="5000" id="field-3"
                                                  className="text-field-form w-input" value={contact.message}
                                                  onChange={handleChange('message')}></textarea>
                                    </div>
                                    <div className="button-wrapper">
                                        <input type="submit" value="Submit" data-wait="Please wait..." className="button-primary w-button"/>
                                    </div>
                                </form>
                                <div className="w-form-done" style={{ display: status === "successful" ? "block" : "none" }}>
                                    <div>Thank you! Your submission has been received!</div>
                                </div>
                                <div className="w-form-fail" style={{ display: status === "error" ? "block" : "none" }}>
                                    <div>Oops! Something went wrong while submitting the form.</div>
                                </div>
                            </div>
                            <div id="w-node-_69bb73d9-4e40-0219-40bd-d4876384cab3-57f55981" className="contact-us-image-wrapper"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ContactUsPage