import unlimitedRequest from "../img/unlimited-resource.svg"
import premiumQuality from "../img/premium-quality.svg"
import flexible from "../img/flexible.svg"
import builtToScale from "../img/built-to-scale.svg"
import fastDelivery from "../img/fast-delivery.svg"

import imgFoodDeliveryOne from "../img/online-food-delivery-customer-app.jpg"
import imgFoodDeliveryTwo from "../img/online-food-delivery-rider-app.jpg"

import imgChurchApp from "../img/church-app.webp"

import imgAiChatbot from "../img/ai-chatbot.webp"
import imgAiChatbotDashboard from "../img/ai-chatbot-dashboard.jpg"

import imgChatWidget from "../img/chat-widget.jpg"
import imgChatWidgetAdmin from "../img/chat-widget-admin.png"

import imgLiveCamera from "../img/live-camera.png"
import imgLiveCameraAdmin from "../img/live-camera-admin.png"

import imgStepOne from "../img/step-one-emoji.svg"
import imgStepTwo from "../img/step-two-emoji.svg"
import imgStepThree from "../img/step-three-emoji.svg"
import imgStepFour from "../img/step-four-emoji.svg"

import imgIconOne from "../img/about-us-icon-one.svg"
import imgIconTwo from "../img/about-us-icon-two.svg"
import imgIconThree from "../img/about-us-icon-three.svg"

import ecommerceAdminPanel from "../img/ecommerce_admin_panel.png"
import ecommerceFrontStore from "../img/ecommerce_front_store.png"

export const navLink = [
    {
        title: "Home",
        url: "/"
    },
    // {
    //     title: "What We Do",
    //     url: "/what-we-do"
    // },
    {
        title: "Our Work",
        url: "/our-work"
    },
    {
        title: "About Us",
        url: "/about-us"
    },
    // {
    //     title: "Our Clients",
    //     url: "/our-clients"
    // },
    {
        title: "Contact Us",
        url: "/contact-us"
    }
]

export const services = [
    {
        title: "Mobile & Web Development",
        description: "We develop state-of-the-art web and mobile applications. All our applications integrate external " +
            "APIs, database, storage, authentication, etc. Our developers rigorously test all our product before deployment."
    },
    {
        title: "Machine Learning",
        description: "We train machine learning models i.e. Convolutional Neural Networks (CNNs), Natural Language Processing " +
            "(NLP), Reinforcement Learning models, etc. We either train from scratch or through Transfer Learning. Don't have datasets " +
            "worry not, our machine learning experts will mine it!"
    },
    {
        title: "Software Development",
        description: "We development desktop applications for windows systems. Our developers uses modern framework to create dynamic " +
            "and agile stand-alone software of your choice."
    },
    {
        title: "White Label Solutions",
        description: "We dedicated our time to develop ready-made white-label solutions. All you just need is to select a solution of " +
            "your choice, we customise it to suit your business and customer's needs and the product is ready to be launched!"
    }
]

export const whiteLabelSolution = [
    {
        title: "eCommerce with Admin Dashboard",
        platform: [
            {
                name: "Front Store"
            },
            {
                name: "Admin Dashboard"
            }
        ],
        shortDescription: "The eCommerce platform comes with a front store for user or customers and the admin dashboard to " +
            "manage products, orders, customers and sales analytics. Users can also pay for the orders through cash on delivery, " +
            "or online payment. Coupon system to drive sales and discounts.",
        url: "ecommerce",
        bgColor: "#ff843f",
        paymentMode: "Subscription",
        whatsIncluded: {
            title:  "Our ecommerce platform solution includes two stand-alone software. These include the front store website and " +
                "an admin dashboard. ",
            point: [
                {
                    name: "The admin dashboard include features to manage content, customers, orders, sales and analytics."
                },
                {
                    name: "The front store website include features such as shopping cart, checkout and order history of the user."
                }
            ]
        },
        features: [
            {
                name: "List more than 10,000+ products"
            },
            {
                name: "Customer authentication and secured database"
            },
            {
                name: "Content and customer management system"
            },
            {
                name: "Customer shopping cart, checkout and shipping system"
            },
            {
                name: "Order, payment and shipping tracking system"
            }
        ],
        images: [
            {
                url: ecommerceAdminPanel,
                title: "eCommerce Admin Dashboard",
                demo: "https://ecommerce-admin-fb00a.web.app"
            },
            {
                url: ecommerceFrontStore,
                title: "eCommerce Front Store",
                demo: "https://ecommerce-e1f82.web.app"
            }
        ]
    },
    {
        title: "Tenders Management Software",
        platform: [
            {
                name: "Admin Dashboard"
            }
        ],
        shortDescription: "The software display all tenders offered by the South African public and private enterprises. " +
            "It allows small-medium business owners to keep track of their tenders and never to miss a deadline. The solution " +
            "offers in-built tools such as price-check, invoice maker, calendar and etc.",
        url: "tenders-management-software",
        bgColor: "#ff843f",
        paymentMode: "Subscription",
        whatsIncluded: {
            title:  "Our tender management system solution includes one stand-alone software. These include the tender management " +
                "website. ",
            point: [
                {
                    name: "The tender management website list all tenders offered by the South African public and private enterprise. " +
                        "The software tracks tenders and make it easy for small business to manage tenders."
                }
            ]
        },
        features: [
            {
                name: "API that displays all public and private tenders"
            },
            {
                name: "Calendar to track all tender important dates"
            },
            {
                name: "Reminders to meet the tender submission deadline"
            },
            {
                name: "Tender checklist to easily manage the tendering process"
            },
            {
                name: "In-built tools such as invoice makers, bill of quantity, etc."
            }
        ],
        images: [
            {
                url: "",
                title: ""
            }
        ]
    },
    {
        title: "Online Food Delivery",
        platform: [
            {
                name: "Customer App"
            },
            {
                name: "Rider App"
            },
            {
                name: "Admin Dashboard"
            }
        ],
        shortDescription: "White Label store front delivery solution that allows business owners to easily manage their deliveries. " +
            "The solution is for restaurants, grocery shops, pharmacy or any similar store front. Our solution provides the " +
            "capability of order management, rider mobile app, customer mobile app and an admin website.",
        url: "online-food-delivery",
        bgColor: "#31d4ad",
        paymentMode: "Subscription",
        whatsIncluded: {
            title:  "Our food delivery solution includes three stand-alone software. These include the admin panel, the delivery app " +
                "and the rider app. ",
            point: [
                {
                    name: "The admin panel receives the orders that can be placed via the customer app. It also allows managing the " +
                        "restaurant’s orders as well as the riders’ accounts."
                },
                {
                    name: "The customer app allows for customers to choose their specific selections and customize their order before " +
                        "placing it."
                },
                {
                    name: "The rider app can accept the orders and also allows for location based zoning as well as the ability to locate " +
                        "customer’s address via google map’s API integration."
                }
            ]
        },
        features: [
            {
                name: "Analytics dashboard for the mobile app"
            },
            {
                name: "Payment integration with Paypal, Stripe and local providers"
            },
            {
                name: "Order tracking feature"
            },
            {
                name: "Email Integration e.g for order confirmation etc."
            },
            {
                name: "Ability to provide ratings and reviews"
            },
            {
                name: "Finding address using GPS integration"
            },
            {
                name: "Phone Number and Google authentication integration"
            },
            {
                name: "Mobile responsive dashboard"
            },
            {
                name: "Stand-alone rider app for order management"
            },
            {
                name: "Multiple variations of food items"
            },
            {
                name: "Push notifications for both mobile and web"
            }
        ],
        images: [
            {
                url: imgFoodDeliveryOne,
                title: "Customer App"
            },
            {
                url: imgFoodDeliveryTwo,
                title: "Rider App"
            }
        ]
    },
    {
        title: "Premium Chat Widget",
        platform: [
            {
                name: "Website Widget"
            }
        ],
        shortDescription: "Get paid to chat with your clients, fans and followers. Add the premium chat widget to your website " +
            "and start generating revenue from chat or messages from your contact form.",
        url: "premium-chat-widget",
        bgColor: "#f46f6f",
        paymentMode: "Subscription",
        whatsIncluded: {
            title:  "Our premium web chat solution includes two stand-alone software. These include the admin panel " +
                "and the web chat widget. ",
            point: [
                {
                    name: "The web chat solution is embedded on your existing website to start having chats with your clients, " +
                        "fans or followers."
                },
                {
                    name: "The admin panel displays user and growth analytics, settings to change availability and payment rates."
                }
            ]
        },
        features: [
            {
                name: "A payment system where users transact to interact with you"
            },
            {
                name: "A Live Chat system allowing real-time chatting"
            },
            {
                name: "Set availability and chat flat-rates"
            },
            {
                name: "Chats are securely stored and can be viewed on the admin dashboard"
            },
            {
                name: "Links to connect to your social media accounts"
            },
            {
                name: "Get to choose whether to accept or reject a chat request"
            }
        ],
        images: [
            {
                url: imgChatWidget,
                title: "Live Chat Widget"
            },
            {
                url: imgChatWidgetAdmin,
                title: "Admin Dashboard"
            }
        ]
    },
    {
        title: "Live Camera",
        platform: [
            {
                name: "Mobile App (Android)"
            },
            {
                name: "Website"
            }
        ],
        shortDescription: "Connect securely your client's cameras to their mobile phone or computer. The solution allows security " +
            "companies and resellers to add revenue from their existing customers.",
        url: "live-camera",
        bgColor: "#d1a4ff",
        paymentMode: "Subscription",
        whatsIncluded: {
            title:  "Our Live Camera solution includes two stand-alone software. These include the admin panel, " +
                "and the live camera app. ",
            point: [
                {
                    name: "The admin panel display analytics, settings to connect and remove your cameras."
                },
                {
                    name: "The live camera app access and display live camera feed from your CCTV cameras through our cloud " +
                        "management system."
                }
            ]
        },
        features: [
            {
                name: "Cloud video management meaning you can access your cameras anywhere"
            },
            {
                name: "Omnichannel - you can access your cameras from any device"
            },
            {
                name: "Backup storage to store critical footage"
            },
            {
                name: "Connect unlimited cameras to our server"
            }
        ],
        images: [
            {
                url: imgLiveCamera,
                title: "Live Camera App"
            },
            {
                url: imgLiveCameraAdmin,
                title: "Admin Dashboard"
            }
        ]
    },
    {
        title: "AI Chatbot",
        platform: [
            {
                name: "Website Widget"
            }
        ],
        shortDescription: "Engage with users who visits your website or interact on your business. Offer instant and 24/7 support " +
            "to your customers - reducing response time and improving customer satisfaction.",
        url: "ai-chatbot",
        bgColor: "#ff843f",
        paymentMode: "Subscription",
        whatsIncluded: {
            title:  "Our AI chatbot solution includes two stand-alone software. These include the admin panel, " +
                "and the chatbot widget. ",
            point: [
                {
                    name: "The admin panel shows analytics, previous chats, users and profile. Reviews setting and support availability " +
                        "can also be enabled under the settings tab."
                },
                {
                    name: "The chatbot widget is embedded onto your website. User can customize it to match the theme of your website."
                }
            ]
        },
        features: [
            {
                name: "The chatbot is AI-enabled to better engage with your customer"
            },
            {
                name: "Conversations are securely stored and can be viewed on the admin panel"
            },
            {
                name: "It customisable to meet your business and customer's needs"
            },
            {
                name: "The chatbot integrates with exend tools and applications"
            }
        ],
        images: [
            {
                url: imgAiChatbot,
                title: "Chatbot Widget"
            },
            {
                url: imgAiChatbotDashboard,
                title: "Admin Dashboard"
            }
        ]
    },
    {
        title: "Custom Church App",
        platform: [
            {
                name: "Mobile App (Android)"
            }
        ],
        shortDescription: "Engage your audience on the go and build connection. The solution is packed with engagement, communication " +
            "and offering features. It is designed for churches and ministries to easily adopt technological tools.",
        url: "custom-church-app",
        bgColor: "#f46f6f",
        paymentMode: "Subscription",
        whatsIncluded: {
            title:  "Our custom church app solution includes two stand-alone applications. These include the mobile church app " +
                "and an admin dashboard. ",
            point: [
                {
                    name: "The admin dashboard allows the ministry leader and staff to add, update and delete content displaying " +
                        "on the app. The dashboard shows analytics of the app."
                },
                {
                    name: "The church mobile app enable you to manage your congregants, share notice of events and any news " +
                        "concerning the ministry."
                }
            ]
        },
        features: [
            {
                name: "Communication through group chats"
            },
            {
                name: "Interactive social and church content news feed"
            },
            {
                name: "Latest church service, events, bible study and many more"
            },
            {
                name: "Audio player with church songs and hymns"
            },
            {
                name: "Prayer wall and mobile bible"
            },
            {
                name: "Event calendar and church bulletin"
            },
            {
                name: "Dashboard to display congregants analytics"
            }
        ],
        images: [
            {
                url: imgChurchApp,
                title: "Custom Church App"
            },
            {
                url: "",
                title: "Admin Dashboard"
            }
        ]
    }
]

export const faq = [
    {
        title: "I don't have money but I have a great idea. Will OnlyDev help me?",
        description: "Yes! OnlyDev will develop the application (web or mobile) you want and as soon as your application start " +
            "generating revenue then we start getting our installments."
    },
    {
        title: "How does your installments work?",
        description: "Our cost calculator will estimate the total bill of development. You choose payment duration. The monthly " +
            "installment will be the total/payment duration"
    },
    {
        title: "Do I get my source code before completing my installments?",
        description: "No! As collateral OnlyDev reserve the rights to the source code until full payment duration is completed."
    },
    {
        title: "Can I make upgrades while still paying installments?",
        description: "Yes! OnlyDev allows its clients to make upgrades and changes to their product before completing their " +
            "installments. Our mission is to create products that better suit your business and clients."
    }
]

export const testimonial = [
    {
        description: "Wegency team is among the best. Easy to collaborate with, detail-oriented and overall great human " +
            "beings. Highly recommend to anyone looking for professionals with high product design acumen.",
        role: "CEO | Symphony Energy",
        companyName: "Hasan Al Bashri"
    },
    {
        description: "Wegency team is among the best. Easy to collaborate with, detail-oriented and overall great human " +
            "beings. Highly recommend to anyone looking for professionals with high product design acumen.",
        role: "Founder | Narrow",
        companyName: "Ermelo"
    }
]

export const ourValues = [
    {
        title: "Unlimited Requests",
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit interdum, " +
            "ac aliquet odio mattis.",
        url: unlimitedRequest,
        id: "w-node-beab3b46-40ef-5508-55b6-bd86500858dd-c714f050"
    },
    {
        title: "Premium Quality",
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit interdum, " +
            "ac aliquet odio mattis.",
        url: premiumQuality,
        id: ""
    },
    {
        title: "Flexible",
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit interdum, " +
            "ac aliquet odio mattis.",
        url: flexible,
        id: "w-node-f30f5bc9-ea75-4133-8857-6797c9a02227-c714f050"
    },
    {
        title: "Built to Scale",
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit interdum, " +
            "ac aliquet odio mattis.",
        url: builtToScale,
        id: ""
    },
    {
        title: "Fast Delivery",
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit interdum, " +
            "ac aliquet odio mattis.",
        url: fastDelivery,
        id: ""
    }
]

export const howItWorks = [
    {
        title: "Step 1: Connect with us",
        description: "Fill the form on the page of the white-label solution of choice.",
        urL: imgStepOne,
        id: "w-node-c8f13602-a7af-13af-a75c-fccde106467f-8ea7170c"
    },
    {
        title: "Step 2: Customize the solution",
        description: "You are assigned a consultant, designer and our tech nerd. They will help to customize the solution " +
            "to better suit your business and customer needs.",
        urL: imgStepTwo,
        id: "w-node-ec78a9ff-8917-0768-d79c-74cdbeefa398-8ea7170c"
    },
    {
        title: "Step 3: Deploy the solution",
        description: "The solution is deployed i.e website is hosted, apps are published, widgets are embedded. Tools such as " +
            "database, payment system, etc are completed integrated.",
        urL: imgStepThree,
        id: "w-node-a40899fe-9b9f-29c5-212a-141e57c02ade-8ea7170c"
    },
    {
        title: "Step 4: Launch the solution",
        description: "Your OnlyDev account credentials are created send to you. Alert your existing and/or prospective customers " +
            "to either visit your business website or download the app.",
        urL: imgStepFour,
        id: "w-node-_519a31da-15fe-052e-08be-0cd3d8fcca91-8ea7170c"
    }
]

export const ourPrinciples = [
    {
        name: "Customize with ease",
        description: "All our white-label solutions are designed in a way that is easy to customize for a variety of businesses.",
        url: imgIconOne
    },
    {
        name: "Quality products",
        description: "All applications developed by our software developers are rigorously tested using modern tools.",
        url: imgIconTwo
    },
    {
        name: "Affordable prices",
        description: "We offer affordable rates to all our applications to remove cost as barrier to entry.",
        url: imgIconThree
    },

]