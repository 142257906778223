import React from "react";
import {whiteLabelSolution} from "../util/constants";
import OurWork from "../components/OurWork";
import {SEO} from "../seo/SEO";

function OurWorkPage() {
    return (
        <div className="page-wrapper">
            <SEO
                title="White-label Solutions | OnlyDev"
                description="Only software development agency in South Africa where you don't pay huge upfront costs but small installments"
                canonicalURL='https://onlydev.co.za/our-work'
            />
            <div className="main-page">
                <div className="case-study-header-section">
                    <div className="main-container">
                        <div className="case-study-header-container">
                            <div id="w-node-a909c03b-a494-b497-f158-91ec1882c1ee-81a0b330" className="section-title-container">
                                <h1 id="w-node-ae99cac4-f6fb-c5a8-06ba-48e9054820bb-81a0b330">
                                    Solutions we crafted to make human lives easier.
                                </h1>
                            </div>
                            <p id="w-node-e2e3a311-22c4-f954-9c95-f6744d234b3a-81a0b330" className="body-text-l-med">
                                Work usually speaks for itself, but our customers have voices too. Check some of the projects
                                we shipped and what folks who worked with us say.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="our-work-section">
                    <div className="main-container">
                        <div className="section-title-container align-center">
                            <h2>This is our work</h2>
                            <p className="body-text-l-reg">
                                Select. Customize. Deploy. Launch!
                            </p>
                        </div>
                        <div className="project-tab-wrapper w-tabs">
                            <div className="w-tab-content">
                                <div data-w-tab="Tab 1" className="w-tab-pane w--tab-active">
                                    <div className="project-list-wrapper w-dyn-list">
                                        <div role="list" className="project-collection-list w-dyn-items">
                                            {whiteLabelSolution.map((item) => (
                                                <OurWork item={item} />
                                            ))}
                                        </div>
                                    </div>
                                </div>
                                <div data-w-tab="Tab 2" className="w-tab-pane">
                                    <div className="project-list-wrapper w-dyn-list">
                                        <div role="list" className="project-collection-list w-dyn-items">
                                            <div role="listitem" className="w-dyn-item">
                                                <div style={{backgroundColor: "#d1a4ff"}}
                                                     className="project-component-wrapper">
                                                    <img alt="" loading="lazy"
                                                         sizes="(max-width: 479px) 78vw, (max-width: 767px) 77vw, (max-width: 991px) 79vw, 339.4375px"
                                                         className="project-image-thumbnail"/>
                                                    <div className="project-tag-wrapper">
                                                        <a href="/service-categories/site-maintenance"
                                                           className="category-tag w-inline-block">
                                                            <div className="body-text-s-med">Site Maintenance</div>
                                                        </a>
                                                        <a href="/service-categories/web-design"
                                                           className="category-tag w-inline-block">
                                                            <div className="body-text-s-med">Web Design</div>
                                                        </a>
                                                        <a href="/service-categories/webflow-development"
                                                           className="category-tag w-inline-block">
                                                            <div className="body-text-s-med">Webflow Development</div>
                                                        </a>
                                                    </div>
                                                    <div>
                                                        <div className="client-name-block">
                                                            <div className="body-text-l-med">Simpo</div>
                                                        </div>
                                                        <div className="project-name">
                                                            <h3>Make a customer-friendly approach visible on your
                                                                website</h3>
                                                        </div>
                                                        <p className="body-text-n-med">Lorem ipsum dolor sit amet,
                                                            consectetur adipiscing elit. Nunc vulputate libero et velit
                                                            interdum, ac aliquet odio mattis. Class aptent taciti
                                                            sociosqu ad litora torquent per conubia nostra, per inceptos
                                                            himenaeos.</p>
                                                    </div>
                                                    <div className="button-wrapper">
                                                        <a href="/casestudies/make-a-customer-friendly-approach-visible-on-your-website"
                                                           className="button-secondary w-button">Learn more</a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div data-w-tab="Tab 3" className="w-tab-pane">
                                    <div className="project-list-wrapper w-dyn-list">
                                        <div role="list" className="project-collection-list w-dyn-items">
                                            <div role="listitem" className="w-dyn-item">
                                                <div style={{backgroundColor: "#f46f6f"}}
                                                     className="project-component-wrapper">
                                                    <img alt="" loading="lazy"
                                                         sizes="(max-width: 479px) 78vw, (max-width: 767px) 77vw, (max-width: 991px) 79vw, 33vw"
                                                         className="project-image-thumbnail"/>
                                                    <div className="project-tag-wrapper">
                                                        <a href="#" className="category-tag w-inline-block">
                                                            <div className="body-text-s-med">Web Design</div>
                                                        </a>
                                                        <a href="/service-categories/webflow-development"
                                                           className="category-tag w-inline-block">
                                                            <div className="body-text-s-med">Webflow Development</div>
                                                        </a>
                                                        <a href="/service-categories/webflow-seo"
                                                           className="category-tag w-inline-block">
                                                            <div className="body-text-s-med">SEO</div>
                                                        </a>
                                                    </div>
                                                    <div>
                                                        <div className="client-name-block">
                                                            <div className="body-text-l-med">Furnira</div>
                                                        </div>
                                                        <div className="project-name">
                                                            <h3>Building Furniture E-commerce site</h3>
                                                        </div>
                                                        <p className="body-text-n-med">Lorem ipsum dolor sit amet,
                                                            consectetur adipiscing elit. Nunc vulputate libero et velit
                                                            interdum, ac aliquet odio mattis. Class aptent taciti
                                                            sociosqu ad litora torquent per conubia nostra, per inceptos
                                                            himenaeos.</p>
                                                    </div>
                                                    <div className="button-wrapper">
                                                        <a href="/casestudies/building-furniture-e-commerce-site"
                                                           className="button-secondary w-button">Learn more</a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div data-w-tab="Tab 4" className="w-tab-pane">
                                    <div className="project-list-wrapper w-dyn-list">
                                        <div role="list" className="project-collection-list w-dyn-items">
                                            <div role="listitem" className="w-dyn-item">
                                                <div style={{backgroundColor: "#31d4ad"}}
                                                     className="project-component-wrapper">
                                                    <img alt="" loading="lazy"
                                                         sizes="(max-width: 479px) 78vw, (max-width: 767px) 77vw, (max-width: 991px) 79vw, 33vw"
                                                         className="project-image-thumbnail"/>
                                                    <div className="project-tag-wrapper">
                                                        <a href="/service-categories/webflow-seo"
                                                           className="category-tag w-inline-block">
                                                            <div className="body-text-s-med">SEO</div>
                                                        </a>
                                                        <a href="/service-categories/web-design"
                                                           className="category-tag w-inline-block">
                                                            <div className="body-text-s-med">Web Design</div>
                                                        </a>
                                                        <a href="/service-categories/site-maintenance"
                                                           className="category-tag w-inline-block">
                                                            <div className="body-text-s-med">Site Maintenance</div>
                                                        </a>
                                                    </div>
                                                    <div>
                                                        <div className="client-name-block">
                                                            <div className="body-text-l-med">Wizly</div>
                                                        </div>
                                                        <div className="project-name">
                                                            <h3>Micro Consulting Platform. Ask, Collaborate &amp;Learn
                                                                for Companies &amp;Businesses</h3>
                                                        </div>
                                                        <p className="body-text-n-med">Lorem ipsum dolor sit amet,
                                                            consectetur adipiscing elit. Nunc vulputate libero et velit
                                                            interdum, ac aliquet odio mattis. Class aptent taciti
                                                            sociosqu ad litora torquent per conubia nostra, per inceptos
                                                            himenaeos.</p>
                                                    </div>
                                                    <div className="button-wrapper">
                                                        <a href="/casestudies/micro-consulting-platform-ask-collaborate-learn-for-companies-businesses"
                                                           className="button-secondary w-button">Learn more</a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div data-w-tab="Tab 5" className="w-tab-pane">
                                    <div className="project-list-wrapper w-dyn-list">
                                        <div role="list" className="project-collection-list w-dyn-items">
                                            <div role="listitem" className="w-dyn-item">
                                                <div style={{backgroundColor: "#31d4ad"}}
                                                     className="project-component-wrapper">
                                                    <img alt="" loading="lazy"
                                                         sizes="(max-width: 479px) 78vw, (max-width: 767px) 77vw, (max-width: 991px) 79vw, 33vw"
                                                         className="project-image-thumbnail"/>
                                                    <div className="project-tag-wrapper">
                                                        <a href="/service-categories/webflow-seo"
                                                           className="category-tag w-inline-block">
                                                            <div className="body-text-s-med">SEO</div>
                                                        </a>
                                                        <a href="/service-categories/web-design"
                                                           className="category-tag w-inline-block">
                                                            <div className="body-text-s-med">Web Design</div>
                                                        </a>
                                                        <a href="/service-categories/site-maintenance"
                                                           className="category-tag w-inline-block">
                                                            <div className="body-text-s-med">Site Maintenance</div>
                                                        </a>
                                                    </div>
                                                    <div>
                                                        <div className="client-name-block">
                                                            <div className="body-text-l-med">Wizly</div>
                                                        </div>
                                                        <div className="project-name">
                                                            <h3>Micro Consulting Platform. Ask, Collaborate &amp;Learn
                                                                for Companies &amp;Businesses</h3>
                                                        </div>
                                                        <p className="body-text-n-med">Lorem ipsum dolor sit amet,
                                                            consectetur adipiscing elit. Nunc vulputate libero et velit
                                                            interdum, ac aliquet odio mattis. Class aptent taciti
                                                            sociosqu ad litora torquent per conubia nostra, per inceptos
                                                            himenaeos.</p>
                                                    </div>
                                                    <div className="button-wrapper">
                                                        <a href="/casestudies/micro-consulting-platform-ask-collaborate-learn-for-companies-businesses"
                                                           className="button-secondary w-button">Learn more</a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="cta-section">
                <div className="main-container">
                    <div className="cta-border">
                        <div className="cta-wrapper">
                            <div id="w-node-bc9ff4d1-cde6-1518-5dcb-627f7a1f9817-7a1f9813" className="cta-title-container">
                                <h1>Have a great project?</h1>
                                <div className="body-text-l-med">Let's discuss your project</div>
                                <div className="button-wrapper">
                                    <a href="/contact-us" className="button-primary w-button">Schedule Call</a>
                                </div>
                            </div>
                            <img
                                loading="lazy" id="w-node-bc9ff4d1-cde6-1518-5dcb-627f7a1f981f-7a1f9813" alt=""
                                className="cta-image"/>
                            <img
                                loading="lazy" id="w-node-bc9ff4d1-cde6-1518-5dcb-627f7a1f9820-7a1f9813" alt=""
                                className="cta-ornamen-2"/>
                            <img
                                loading="lazy" id="w-node-bc9ff4d1-cde6-1518-5dcb-627f7a1f9821-7a1f9813" alt=""
                                className="cta-ornamen-1"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default OurWorkPage