import React from "react";

function WhatWeDoPage() {
    return (
        <div className="page-wrapper">
            <div className="main-page">
                <div className="what-we-do-hero-section">
                    <div className="main-container">
                        <div className="what-we-do-hero-container">
                            <div className="what-we-do-hero-heading-container">
                                <h1>Everything you need in one place.</h1>
                                <div id="w-node-_14bb8130-d9d6-bfbf-f019-2c9f7550a341-424fe50b"
                                     className="what-we-do-hero-content">
                                    <p className="body-text-l-med">We are your all-in-one team, we work with you
                                        personally to deliver bespoke services for you or your clients, just as you
                                        would do if you hired internally. From design, to development and marketing. Let
                                        our team become your team.</p>
                                </div>
                            </div>
                        </div>
                        <img
                            loading="lazy" alt="" sizes="88vw"
                            className="what-we-do-hero-image"/>
                    </div>
                </div>
                <div className="what-we-do-service-section">
                    <div className="main-container">
                        <div className="what-we-do-service-content-wrapper">
                            <div className="section-title-container what-we-do-section">
                                <h2 className="text-color-white">We make your ideas come to life</h2>
                            </div>
                            <div className="split-content reversed">
                                <div id="w-node-f8e4ce2c-ada2-5c2d-c04c-16d17ff1575b-424fe50b"
                                     className="what-we-do-service-content-block">
                                    <div className="body-text-s-med text-color-gray10">Webflow Development</div>
                                    <h3 className="color-text-white">Crafting high-performing scalable Webflow
                                        sites</h3>
                                    <div className="margin-top-24px">
                                        <p className="body-text-n-reg color-text-grey10">Lorem ipsum dolor sit amet,
                                            consectetur adipiscing elit. Nunc vulputate libero et velit interdum, ac
                                            aliquet odio mattis. Class aptent taciti sociosqu ad litora torquent per
                                            conubia nostra.</p>
                                    </div>
                                </div>
                                <div id="w-node-f8e4ce2c-ada2-5c2d-c04c-16d17ff15763-424fe50b"
                                     className="service-image-wrapper">
                                    <img
                                        loading="lazy" alt=""
                                        sizes="(max-width: 767px) 88vw, (max-width: 991px) 40vw, 530px"
                                        className="what-we-do-image"/>
                                </div>
                            </div>
                            <div className="split-content">
                                <div id="w-node-b44e74f9-3450-a29d-b150-c17f5fbdd49f-424fe50b"
                                     className="what-we-do-service-content-block">
                                    <div className="body-text-s-med text-color-gray10">Web Design</div>
                                    <h3 className="color-text-white">We’re very passionate in Web Design</h3>
                                    <div className="margin-top-24px">
                                        <p className="body-text-n-reg color-text-grey10">Lorem ipsum dolor sit amet,
                                            consectetur adipiscing elit. Nunc vulputate libero et velit interdum, ac
                                            aliquet odio mattis. Class aptent taciti sociosqu ad litora torquent per
                                            conubia nostra, per inceptos himenaeos.</p>
                                    </div>
                                </div>
                                <div className="service-image-wrapper">
                                    <img
                                        loading="lazy" alt=""
                                        sizes="(max-width: 767px) 88vw, (max-width: 991px) 40vw, 530px"
                                        className="what-we-do-image"/>
                                </div>
                            </div>
                            <div className="split-content reversed">
                                <div id="w-node-_42740dc6-56ba-d4f3-4335-93fc13ac5ddc-424fe50b"
                                     className="what-we-do-service-content-block">
                                    <div className="body-text-s-med text-color-gray10">Site Maintenance</div>
                                    <h3 className="color-text-white">Migrate Your Wordpress site to Webflow
                                        Properly</h3>
                                    <div className="margin-top-24px">
                                        <p className="body-text-n-reg color-text-grey10">Lorem ipsum dolor sit amet,
                                            consectetur adipiscing elit. Nunc vulputate libero et velit interdum, ac
                                            aliquet odio mattis. Class aptent taciti sociosqu ad litora torquent per
                                            conubia nostra.</p>
                                    </div>
                                </div>
                                <div id="w-node-_42740dc6-56ba-d4f3-4335-93fc13ac5de4-424fe50b"
                                     className="service-image-wrapper">
                                    <img
                                        loading="lazy" alt=""
                                        sizes="(max-width: 767px) 88vw, (max-width: 991px) 40vw, 530px"
                                        className="what-we-do-image"/>
                                </div>
                            </div>
                            <div className="split-content">
                                <div id="w-node-_1842960b-9ffa-48d6-71c2-d7c5faf81598-424fe50b"
                                     className="what-we-do-service-content-block">
                                    <div className="body-text-s-med text-color-gray10">Webflow SEO</div>
                                    <h3 className="color-text-white">Webflow Powerful SEO Optimization</h3>
                                    <div className="margin-top-24px">
                                        <p className="body-text-n-reg color-text-grey10">Lorem ipsum dolor sit amet,
                                            consectetur adipiscing elit. Nunc vulputate libero et velit interdum, ac
                                            aliquet odio mattis. Class aptent taciti sociosqu ad litora torquent per
                                            conubia nostra, per inceptos himenaeos.</p>
                                    </div>
                                </div>
                                <div className="service-image-wrapper">
                                    <img
                                        loading="lazy" alt=""
                                        sizes="(max-width: 767px) 88vw, (max-width: 991px) 40vw, 530px"
                                        className="what-we-do-image"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="cta-section">
                <div className="main-container">
                    <div className="cta-border">
                        <div className="cta-wrapper">
                            <div id="w-node-bc9ff4d1-cde6-1518-5dcb-627f7a1f9817-7a1f9813"
                                 className="cta-title-container">
                                <h1>Have a great project?</h1>
                                <div className="body-text-l-med">Let &#x27;s discuss your project</div>
                                <div className="button-wrapper">
                                    <a href="/contact-us" className="button-primary w-button">Schedule Call</a>
                                </div>
                            </div>
                            <img
                                loading="lazy" id="w-node-bc9ff4d1-cde6-1518-5dcb-627f7a1f981f-7a1f9813" alt=""
                                className="cta-image"/>
                            <img
                                loading="lazy" id="w-node-bc9ff4d1-cde6-1518-5dcb-627f7a1f9820-7a1f9813" alt=""
                                className="cta-ornamen-2"/>
                            <img
                                loading="lazy" id="w-node-bc9ff4d1-cde6-1518-5dcb-627f7a1f9821-7a1f9813" alt=""
                                className="cta-ornamen-1"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default WhatWeDoPage