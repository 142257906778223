import firebase from "firebase/compat/app";
import "firebase/compat/database"
import "firebase/compat/auth"
import "firebase/compat/analytics"

const firebaseApp = firebase.initializeApp({
    apiKey: "AIzaSyB-8lAsDTgdkdKHHnaxX-XvZnJM2BiDCFI",
    authDomain: "onlydev-199ae.firebaseapp.com",
    databaseURL: "https://onlydev-199ae-default-rtdb.firebaseio.com",
    projectId: "onlydev-199ae",
    storageBucket: "onlydev-199ae.appspot.com",
    messagingSenderId: "234319470054",
    appId: "1:234319470054:web:670d00d433e081da534a27",
    measurementId: "G-WWT9JVJEM7"
})

const auth = firebaseApp.auth()
const db = firebaseApp.database()
const analytics = firebaseApp.analytics()

analytics.setAnalyticsCollectionEnabled(true)

export { auth, db }