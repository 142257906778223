import React from "react";
import logo from "../img/logo192.png";

function Logo() {
    return (
        <div className="logo-brand-container">
            <a href="/" aria-current="page" className="w-nav-brand w--current">
                <div style={{ display: "flex", alignItems: "center", columnGap: 12 }}>
                    <img src={logo} loading="lazy" alt="" style={{ height: 50 }}/>
                    <div>
                        <h3 className="text-style-h3">
                            <span style={{ color: '#f46f6f'}}>Only</span>Dev</h3>
                        <p style={{ fontSize: 9.5, marginTop: -8 }}>NO HUGE UPFRONT COSTS</p>
                    </div>
                </div>

            </a>
        </div>
    )
}

export default Logo