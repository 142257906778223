import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';
import React from 'react';

function SEO({ title, description, image, canonicalURL, noIndex }) {
    return (
        <Helmet title={title}>
            <meta name="description" content={description} />
            <meta name="image" content={image} />

            {canonicalURL && <link rel="canonical" href={canonicalURL} />}

            {title && <meta property="og:title" content={title} />}

            {description && <meta property="og:description" content={description} />}

            {image && <meta property="og:image" content={image} />}

            {noIndex && <meta name="robots" content="noindex" />}
        </Helmet>
    );
}

export { SEO };

SEO.propTypes = {
    title: PropTypes.string,
    description: PropTypes.string,
    image: PropTypes.string,
    canonical: PropTypes.string,
    noIndex: PropTypes.bool,
};

SEO.defaultProps = {
    title: 'OnlyDev - We Develop Software and you Pay in Installments',
    description:
        "Only software development agency in South Africa where you don't pay huge upfront costs but small installments.",
    image: '',
    canonicalURL: 'https://onlydev.co.za/',
    noIndex: false,
};