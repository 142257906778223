import React from "react";
import {faq, services, whiteLabelSolution} from "../util/constants";
import FAQ from "../components/FAQ";
import unlimitedRequest from "../img/unlimited-resource.svg"
import premiumQuality from "../img/premium-quality.svg"
import flexible from "../img/flexible.svg"
import builtToScale from "../img/built-to-scale.svg"
import fastDelivery from "../img/fast-delivery.svg"
import OurWork from "../components/OurWork";
import {SEO} from "../seo/SEO";
import OurService from "../components/OurService";
import team from "../img/team.jpeg"
import imgArrowUpRight from "../img/arrow-up-right.svg"
import imgPointer from "../img/pointer.svg"
import imgCta from "../img/cta-image.png"
import imgCtaOne from "../img/cta-image-one.svg"
import imgCtaTwo from "../img/cta-image-two.png"

function HomePage() {

    return (
        <div>
            <SEO
                title="OnlyDev - We Develop Software and you Pay in Installments"
                description="Only software development agency in South Africa where you don't pay huge upfront costs but small installments"
                canonicalURL='https://onlydev.co.za/'
            />
            <div className="page-wrapper">
                <div className="hero-section">
                    <div className="main-container">
                        <div className="hero-container">
                            <div className="hero-heading-block">
                                <h1 className="display-text">
                                    No <span className="text-span"> Huge Upfront</span> Costs Pay Small Installments
                                </h1>
                                <p className="body-text-l-med">
                                    We are the <strong>Only Software Development</strong> agency that allow our clients to
                                    pay development costs in affordable installments. No more huge upfront costs or Once-off
                                    payments. Choose a software and start paying in small installments after development is
                                    completed.
                                </p>
                                <div className="button-group">
                                    <a href="/our-work" className="button-primary w-button hero-buttons">
                                        See Our Work
                                    </a>
                                    <a href="/about-us" className="button-secondary w-button hero-buttons">
                                        About Us
                                    </a>
                                </div>
                            </div>
                            <div className="hero-service-grid">
                                <div className="service-heading-block">
                                    <div>
                                        <h2>We create world-class mobile and web apps, and machine learning models.</h2>
                                        <div className="button-wrapper">
                                            <div href="/what-we-do" className="button-secondary w-button">Our Services</div>
                                        </div>
                                    </div>
                                </div>
                                <div id="w-node-_481ee196-77c3-3a5a-0aeb-7973c20246af-c714f050" className="service-dropdown-block">
                                    <div className="service-dropdown-wrapper">
                                        <div className="line-divider"></div>
                                        {services.map((item, key) => (
                                            <OurService item={item} />
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="project-section">
                    <div className="main-container">
                        <div className="project-heading-block">
                            <div className="project-title-wrapper">
                                <h2>White Label Solutions</h2>
                                <div className="body-text-l-med">
                                    Customize. Deploy. Start-up.
                                </div>
                            </div>
                            <a data-w-id="55e07648-9878-93fc-ec0c-0256fe32c530" href="/our-work" className="button-with-icon w-inline-block">
                                <div className="button-text">See More</div>
                                <img src={imgArrowUpRight} loading="lazy" data-w-id="55e07648-9878-93fc-ec0c-0256fe32c533" alt=""/>
                            </a>
                        </div>
                        <div className="project-list-wrapper w-dyn-list">
                            <div role="list" className="project-collection-list w-dyn-items">
                                {whiteLabelSolution.slice(0, 4).map((item) => (
                                    <OurWork item={item} />
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="about-us-section">
                    <div className="main-container">
                        <div className="w-layout-grid about-us-grid">
                            <img src={team} loading="lazy" id="w-node-f6947264-5398-f533-4e43-6268ef4b8d08-c714f050" alt=""
                                sizes="88vw" className="image"/>
                            <div id="w-node-cb7ecd93-f994-072c-7ece-c3081dddf470-c714f050" className="about-us-hero-wrapper">
                                <h2>
                                    We are digital experts specialized in web and mobile development. Our mission is to break
                                    the cest barrier that limits small-medium business to adopt technology!
                                </h2>
                                <div className="button-wrapper">
                                    <a data-w-id="55e07648-9878-93fc-ec0c-0256fe32c530" href="/about-us" className="button-with-icon w-inline-block">
                                        <div className="button-text">More About Us</div>
                                        <img src={imgArrowUpRight} loading="lazy" data-w-id="55e07648-9878-93fc-ec0c-0256fe32c533" alt=""/>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="our-values-section">
                    <div className="main-container">
                        <div className="w-layout-grid our-value-grid">
                            <div id="w-node-_55aadb0e-d301-00c5-5203-1f2ec30ec9c8-c714f050" className="our-value-block header">
                                <div className="body-text-l-med">What drives us</div>
                                <h2>Our Values</h2>
                            </div>
                            <div id="w-node-_010ce4ff-be8c-c114-063c-8aa9998a79c8-c714f050" className="our-value-block border1">
                                <img src={unlimitedRequest} loading="lazy" alt=""/>
                                <div className="out-value-wrapper">
                                    <div className="heading-values">
                                        <h3>Unlimited Requests</h3>
                                    </div>
                                    <p className="body-text-l-reg">
                                        We always meet the client's demands. The client can select more than one application to
                                        meet their business demand.
                                    </p>
                                </div>
                            </div>
                            <div id="w-node-f310f2f0-c142-de15-69cc-8cb69921edb7-c714f050" className="our-value-block border2">
                                <img src={premiumQuality} loading="lazy" alt=""/>
                                <div className="out-value-wrapper">
                                    <div className="heading-values">
                                        <h3>Premium Quality</h3>
                                    </div>
                                    <p className="body-text-l-reg">
                                        Rigorous test are implemented using modern tools on all our applications before they
                                        are deployed to users.
                                    </p>
                                </div>
                            </div>
                            <div id="w-node-_8be5a13e-dda2-b397-8e0e-7101752f1e14-c714f050" className="our-value-block border5">
                                <img src={flexible} loading="lazy" alt=""/>
                                <div className="out-value-wrapper">
                                    <div className="heading-values">
                                        <h3>Flexible Payment</h3>
                                    </div>
                                    <p className="body-text-l-reg">
                                        No huge upfront costs! Our flexible payment breaks barrier to develop applications for
                                        entrepreneurs who want to adopt digital solutions.
                                    </p>
                                </div>
                            </div>
                            <div id="w-node-_951c3cab-a988-15ba-e714-c56a8b8d7f45-c714f050" className="our-value-block border4">
                                <img src={builtToScale} loading="lazy" alt=""/>
                                <div className="out-value-wrapper">
                                    <div className="heading-values">
                                        <h3>Built to Scale</h3>
                                    </div>
                                    <p className="body-text-l-reg">
                                        All our applications can utilize thousands of datasets and can be downloaded by hundreds
                                        of thousand of users.
                                    </p>
                                </div>
                            </div>
                            <div id="w-node-_9639ce9e-a6b9-71cc-cf9a-9c6e768dbadb-c714f050" className="our-value-block border3">
                                <img src={fastDelivery} loading="lazy" alt=""/>
                                <div className="out-value-wrapper">
                                    <div className="heading-values">
                                        <h3>Fast Delivery</h3>
                                    </div>
                                    <p className="body-text-l-reg">
                                        Our white-label solution applications its just customise and deploy. Our turnaround
                                        time is between 3 ~ 7 days.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="faq-section">
                    <div className="main-container">
                        <div className="faq-wrapper">
                            <div className="faq-title-block">
                                <img
                                    src="https://assets.website-files.com/637c671b69054e606714f04f/637e0b57f3ef83eca0ca7f88_Ornamen%201.svg"
                                    loading="lazy" alt="" data-w-id="8fc33947-8cdb-cc07-df51-53bba5c067bf"
                                    className="faq-ornamen-1"/>
                                <img
                                    src="https://assets.website-files.com/637c671b69054e606714f04f/637e0b5742ffe8bd546d932a_Ornamen%202.svg"
                                    loading="lazy" alt="" className="faq-ornamen-2"/>
                                <h2>FAQs</h2>
                                <div className="body-text-l-med">Here are few most commonly ask questions by our
                                    clients
                                </div>
                            </div>
                            <div className="faq-dropdown-component">
                                {faq.map((item) => (
                                    <FAQ item={item} />
                                ))}
                            </div>
                            <div className="faq-cta-block">
                                <div className="faq-title-block">
                                    <h2>Still have questions?</h2>
                                    <div className="body-text-l-reg">Support details to capture customers that might be
                                        on the fence.
                                    </div>
                                </div>
                                <img src={imgPointer} loading="lazy" alt="" className="pointer-ornamen"/>
                                <div className="button-wrapper">
                                    <a data-w-id="55e07648-9878-93fc-ec0c-0256fe32c530" href="/contact-us" className="button-with-icon w-inline-block">
                                        <div className="button-text">Contact Us</div>
                                        <img src={imgArrowUpRight} loading="lazy" data-w-id="55e07648-9878-93fc-ec0c-0256fe32c533" alt=""/>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="line-divider"></div>
                <div className="cta-section">
                    <div className="main-container">
                        <div className="cta-border">
                            <div className="cta-wrapper">
                                <div id="w-node-bc9ff4d1-cde6-1518-5dcb-627f7a1f9817-7a1f9813" className="cta-title-container">
                                    <h1>Have a great project?</h1>
                                    <div className="body-text-l-med">
                                        Let's discuss your project
                                    </div>
                                    <div className="button-wrapper">
                                        <a href="/contact-us" className="button-primary w-button">
                                            Schedule a call
                                        </a>
                                    </div>
                                </div>
                                <img src={imgCta} loading="lazy" id="w-node-bc9ff4d1-cde6-1518-5dcb-627f7a1f981f-7a1f9813"
                                     alt="" className="cta-image"/>
                                <img src={imgCtaOne} loading="lazy" id="w-node-bc9ff4d1-cde6-1518-5dcb-627f7a1f9820-7a1f9813"
                                     alt="" className="cta-ornamen-2"/>
                                <img src={imgCtaTwo} loading="lazy" id="w-node-bc9ff4d1-cde6-1518-5dcb-627f7a1f9821-7a1f9813"
                                     alt="" className="cta-ornamen-1"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default HomePage