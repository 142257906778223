import React, {useState} from "react";
import {Link, useParams} from "react-router-dom";
import {howItWorks, whiteLabelSolution} from "../util/constants";
import imgThumbnail from "../img/thumbnail.jpeg"
import {auth, db} from "../firebase";
import imgCta from "../img/cta-image.png"
import imgCtaOne from "../img/cta-image-one.svg"
import imgCtaTwo from "../img/cta-image-two.png"

function ProductDetailPage() {
    const {productUrl} = useParams()

    const [status, setStatus] = useState("")
    const [solution, setSolution] = useState({
        firstName: "",
        surname: "",
        email: "",
        phoneNumber: ""
    })

    const handleChange = name => event => {
        const value = event.target.value
        setSolution({ ...solution, [name]: value })

    }

    const submitSolution = (e, category) => {
        e.preventDefault()

        const postId = db.ref().push().key

        auth.signInAnonymously().then((authUser) => {
            db.ref("whiteLabelSolution").child(`/${authUser.user.uid}/${postId}`).set({
                firstName: solution.firstName,
                surname: solution.surname,
                phoneNumber: solution.phoneNumber,
                email: solution.email,
                postId: postId,
                userId: authUser.user.uid,
                dateSent: new Date().getTime(),
                category: category
            }).then(() => {
                setSolution({ firstName: "", surname: "", email: "", phoneNumber: "" })
                setStatus("successful")

            }).catch(() => {
                setStatus("error")
            })
        }).catch(() => {
            setStatus("error")
        })
    }

    return (
        <div className="page-wrapper">
            {whiteLabelSolution.filter((search) => search.url === productUrl).map((item) => (
                <div className="main-page">
                    <div style={{backgroundColor: item.bgColor }} className="case-study-hero-section">
                        <div className="main-container">
                            <div className="case-study-hero-container">
                                <div id="w-node-bdf8dbef-f68a-7074-4ac5-f338162ac4d4-bd82b628" className="hero-text-block">
                                    <h1>{item.title}</h1>
                                    <p className="margin-top-10px">{item.shortDescription}</p>
                                    <div className="button-wrapper">
                                        <a href="#project-detail" className="button-primary w-button">
                                            See Project Detail
                                        </a>
                                    </div>
                                </div>
                                <img alt="" loading="lazy" id="w-node-_678e7b04-005d-54c6-7cfa-4fb84b1cac2c-bd82b628"
                                     style={{ border: "3px solid #000", borderRadius: "8px" }}
                                     src={imgThumbnail} sizes="(max-width: 991px) 88vw, 100vw" className="project-image"/>
                            </div>
                        </div>
                    </div>
                    <div className="brief-section">
                        <div className="main-container">
                            <div className="brief-container">
                                <div id="w-node-e6151de8-2efd-129c-7dd5-a8ea8f12dbc3-bd82b628" className="brief-text-container">
                                    <h2 className="color-text-white">❓ What is included:</h2>
                                    <p className="body-text-n-reg color-text-grey10">{item.whatsIncluded["title"]}</p>
                                    <ul>
                                        {item.whatsIncluded["point"].map((pointItem) => (
                                            <li style={{ paddingTop: 12}} className="body-text-n-reg color-text-grey10">
                                                <p className="body-text-n-reg color-text-grey10">{pointItem.name}</p>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="project-overview-section">
                        <div className="main-container">
                            <div id="project-detail" className="project-overview-container">
                                <div id="w-node-_8e4fd996-abe3-2350-fee5-1c0646f4513d-bd82b628" className="rich-text-container">
                                    <div className="rich-text-element w-richtext">
                                        <h2>🔥 Features</h2>
                                        <ul>
                                            {item.features.map((featureItem) => (
                                                <li style={{ paddingTop: 12}} className="body-text-n-reg">
                                                    <p className="body-text-n-reg">{featureItem.name}</p>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                </div>
                                <div id="w-node-_4495b2ab-f3d0-8635-3cf0-a044707243b9-bd82b628" className="case-study-navigation-component">
                                    <h3>Feels like this is your plan?</h3>
                                    <div className="">
                                        <div className="form-cta-component w-form" style={{ paddingRight: 0 }}>
                                            <form id="wf-form-Form" name="wf-form-Form" className="form-wrapper" onSubmit={(e) => submitSolution(e, item.title)}>
                                                <div className="form-field-wrapper">
                                                    <label htmlFor="name" className="form-label-text">First Name</label>
                                                    <input type="text" className="text-field-form w-input" required value={solution.firstName}
                                                           placeholder="Enter your name" id="name" onChange={handleChange('firstName')}/>
                                                </div>
                                                <div className="form-field-wrapper">
                                                    <label htmlFor="surname" className="form-label-text">Surname</label>
                                                    <input type="text" className="text-field-form w-input" required value={solution.surname}
                                                           placeholder="Enter your surname" id="surname" onChange={handleChange('surname')}/>
                                                </div>
                                                <div className="form-field-wrapper">
                                                    <label htmlFor="Email-3" className="form-label-text">Email Address</label>
                                                    <input type="email" className="text-field-form w-input" value={solution.email}
                                                           placeholder="Enter your email" id="Email-3" required onChange={handleChange('email')}/>
                                                </div>
                                                <div className="form-field-wrapper">
                                                    <label htmlFor="phone" className="form-label-text">Phone Number</label>
                                                    <input type="tel" className="text-field-form w-input" required value={solution.phoneNumber}
                                                           placeholder="+27 11 123 4567" id="phone" onChange={handleChange('phoneNumber')} />
                                                </div>
                                                <div className="button-wrapper">
                                                    <input type="submit" value="Submit" data-wait="Please wait..." className="button-primary w-button"/>
                                                </div>
                                            </form>
                                            <div className="w-form-done" style={{ display: status === "successful" ? "block" : "none" }}>
                                                <div>Thank you! Your submission has been received!</div>
                                            </div>
                                            <div className="w-form-fail" style={{ display: status === "error" ? "block" : "none" }}>
                                                <div>Oops! Something went wrong while submitting the form.</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="why-us-section">
                        <div className="main-container">
                            <div className="why-us-container">
                                <div className="">
                                </div>
                            </div>
                            <div className="timeline-container ">
                                <div id="w-node-_9ce61b99-c58d-8ab8-d60c-316b636132fb-8ea7170c" className="timeline-heading-block">
                                    <div id="w-node-cb2a6455-8136-1796-36b1-61b4dac00447-8ea7170c" className="timeline-heading-sticky">
                                        <h2>🔂 <br/>How It Works!</h2>
                                    </div>
                                </div>
                                <div className="timeline-wrapper">
                                    {howItWorks.map((howItWorkItem) => (
                                        <div id={howItWorkItem.id} className="timeline-component">
                                            <div id={howItWorkItem.id} className="timeline-content-wrapper">
                                                <div id={howItWorkItem.id} className="timeline-icon-block">
                                                    <img src={howItWorkItem.urL} loading="lazy" alt=""/>
                                                </div>
                                                <div className="timeline-line"></div>
                                            </div>
                                            <div id={howItWorkItem.id} className="timeline-text-block">
                                                <div>
                                                    <div className="text-style-h3">{howItWorkItem.title}</div>
                                                    <p className="body-text-n-reg margin-top-10px" style={{ marginBottom: 42}}>
                                                        {howItWorkItem.description}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="our-team-section">
                        <div className="main-container">
                            <div className="our-team-container">
                                <div className="w-dyn-list">
                                    <div role="list" id="project-images" className="w-dyn-items w-layout-grid">
                                        {item.images.map((imgItem) => (
                                            <div role="listitem" className="w-dyn-item">
                                                <div className="team-member-card-wrapper">
                                                    <img src={imgItem.url} loading="lazy" alt="" className="member-image"/>
                                                    <div className="team-information-container w-inline-block">
                                                        <h3>{imgItem.title}</h3>
                                                    </div>
                                                    <a href={imgItem.demo} target="_blank">
                                                        View Demo
                                                    </a>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ))}
            <div className="cta-section">
                <div className="main-container">
                    <div className="cta-border">
                        <div className="cta-wrapper">
                            <div id="w-node-bc9ff4d1-cde6-1518-5dcb-627f7a1f9817-7a1f9813" className="cta-title-container">
                                <h1>Have a great project?</h1>
                                <div className="body-text-l-med">Let's discuss your project</div>
                                <div className="button-wrapper">
                                    <a href="/contact-us" className="button-primary w-button">Schedule a Call</a>
                                </div>
                            </div>
                            <img src={imgCta} loading="lazy" id="w-node-bc9ff4d1-cde6-1518-5dcb-627f7a1f981f-7a1f9813" alt=""
                                className="cta-image"/>
                            <img src={imgCtaOne} loading="lazy" id="w-node-bc9ff4d1-cde6-1518-5dcb-627f7a1f9820-7a1f9813" alt=""
                                className="cta-ornamen-2"/>
                            <img src={imgCtaTwo} loading="lazy" id="w-node-bc9ff4d1-cde6-1518-5dcb-627f7a1f9821-7a1f9813" alt=""
                                className="cta-ornamen-1"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ProductDetailPage