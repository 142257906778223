import React, {useState} from "react";
import imgArrowDown from "../img/arrow-down.svg"

function FAQ({ item }) {
    const [openAccordion, setAccordion] = useState(false)

    const handleAccordion = () => {
        if (openAccordion) {
            setAccordion(false)
        } else {
            setAccordion(true)
        }
    }

    return (
        <div data-hover="false" data-delay="0" className="faq-dropdown w-dropdown">
            <div className="faq-dropdown-toggle w-dropdown-toggle w--open">
                <div className="faq-toggle-wrapper" onClick={handleAccordion}>
                    <div className="question-text" style={{ maxWidth: "90%"}}>{item.title}</div>
                    <img src={imgArrowDown} loading="lazy" alt="" className="dropdown-icon-toggle"
                         style={{ transform: openAccordion ? `translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(-180deg) skew(0deg, 0deg)` :
                                 `translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)`, transformStyle: "preserve-3d" }}/>
                </div>
            </div>
            <nav className="dropdown-answer-wrapper w-dropdown-list w--open" style={{ height: !openAccordion && "0px" }}>
                <p>{item.description}</p>
            </nav>
        </div>
    )
}

export default FAQ