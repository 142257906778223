import React from "react";
import imgAboutUsHero from "../img/about-us-hero.webp"
import imgAboutUs from "../img/about-us-star.svg"
import {ourPrinciples} from "../util/constants";
import {SEO} from "../seo/SEO";
import imgCta from "../img/cta-image.png"
import imgCtaOne from "../img/cta-image-one.svg"
import imgCtaTwo from "../img/cta-image-two.png"

function AboutPage() {
    return (
        <div className="page-wrapper">
            <SEO
                title="About Our Software Delveloment Agency | OnlyDev"
                description="OnlyDev is dedicated to making affordable, quality software more accessible while keeing the cost low through our monthly installment payment plan."
                canonicalURL='https://onlydev.co.za/about-us'
            />
            <div className="main-page">
                <div className="about-us-hero-section">
                    <div className="main-container">
                        <div className="about-us-hero-container">
                            <div className="title-align-center-block container-width-medium">
                                <h1>Great brands have great stories. This is ours.</h1>
                                <p className="body-text-l-med margin-top-16px">
                                    We create websites and mobile applications that help businesses
                                    to connect with customers in a meaningful way, to increase sales and enable
                                    business growth.
                                </p>
                            </div>
                            <div className="about-us-hero-image-wrapper">
                                <img src={imgAboutUsHero} loading="lazy" alt="" sizes="88vw" className="image"/>
                                <img src={imgAboutUs} loading="lazy" alt="" className="about-us-hero-ornamen-1"/>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="why-us-section">
                    <div className="main-container">
                        <div className="why-us-container">
                            <div className="title-align-center-block">
                                <div className="title-text">Why Us</div>
                                <h2>Our principles that keep us moving forward together.</h2>
                            </div>
                        </div>
                        <div className="w-layout-grid why-us-grid">
                            {ourPrinciples.map((item) => (
                                <div id="w-node-_56531cb9-4660-c67c-28f7-e63209b12e8a-09b12e81" className="why-us-content-container">
                                    <img src={item.url} loading="lazy" alt=""/>
                                    <div className="why-us-content-block">
                                        <h4>{item.name}</h4>
                                        <p className="body-text-n-reg">{item.description}</p>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
            <div className="cta-section">
                <div className="main-container">
                    <div className="cta-border">
                        <div className="cta-wrapper">
                            <div id="w-node-bc9ff4d1-cde6-1518-5dcb-627f7a1f9817-7a1f9813" className="cta-title-container">
                                <h1>Have a great project?</h1>
                                <div className="body-text-l-med">Let's discuss your project</div>
                                <div className="button-wrapper">
                                    <a href="/contact-us" className="button-primary w-button">Schedule a Call</a>
                                </div>
                            </div>
                            <img src={imgCta} loading="lazy" id="w-node-bc9ff4d1-cde6-1518-5dcb-627f7a1f981f-7a1f9813" alt=""
                                className="cta-image"/>
                            <img src={imgCtaOne} loading="lazy" id="w-node-bc9ff4d1-cde6-1518-5dcb-627f7a1f9820-7a1f9813" alt=""
                                className="cta-ornamen-2"/>
                            <img src={imgCtaTwo} loading="lazy" id="w-node-bc9ff4d1-cde6-1518-5dcb-627f7a1f9821-7a1f9813" alt=""
                                className="cta-ornamen-1"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AboutPage